<template>
<div class="d-flex">
  <!-- AppDomain  -->
  <div class="w-50">
      Current Application Domain:
  </div>
  <div id="AppDomain" class="col-auto w-50">
    <DropdownInput
    :value = "applicationDomain" 
    :options = "appdomains" 
    @on_change_input_value = "onTextChange" >
    </DropdownInput>

    <btn  class="btn-sm" :name="'Testing'" :color="'light-grey'"></btn>
  </div>
</div>

</template>

<script>
import DataServices from '@/services/data-service';
import { selectedDomain } from "@/components/Navigation/navState";
import { getAuthID } from '../helpers';
import DropdownInput from '../components/DropdownInput.vue';
import Btn from '../components/Btn.vue';

export default {
  components: { DropdownInput, Btn },
  name:'Profile',
  data(){
    return{
      appdomains:[],
      applicationDomain:'',
    }
  },
  methods: {
    async AppDomains() {
      this.appdomains = await DataServices.getAppDomain();
    },
    selectedAppDomain(){
      selectedDomain(this.applicationDomain)
    },
    onTextChange (x) {
      this.applicationDomain = x;
      selectedDomain(x)
    },
  },
  async created(){
    const user = await getAuthID();
    this.applicationDomain = user.applicationDomain
  },
  mounted(){
    this.AppDomains()
  }
}
</script>

<style>

</style>